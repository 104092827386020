import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import "twin.macro"
import Button from "../components/button"
import { useUser } from "../hooks/useUser"
import AccountLayout from "../components/accountLayout"
import PasswordChecklist from "../components/passwordChecklist"
import UiStoreContext from "../contexts/uiStoreContext"
import { observer } from "mobx-react-lite"
import SEO from '../components/seo'
import { CognitoUser } from "../classes/user"

const passwordValidator = require('password-validator');
const emailValidator = require('email-validator')

// create a password schema
const schema = new passwordValidator();

schema
	.is()
	.min(8)
	.has()
	.uppercase()
	.has()
	.lowercase()
	.has()
	.digits()
	.has()
	.symbols();

const SignUp = observer((props) => {

	const { register, errors, watch, handleSubmit } = useForm({
		defaultValues: {
			optinMarketing: false,
			optinUpdates: false
		}
	});

	const watchPassword = watch("password")
	const [signupErr, setSignupErr] = useState()
	const { signup, user, refreshAuthenticatedUser } = useUser()
	const uiStore = useContext(UiStoreContext)
	const [loading, setLoading] = useState(true)
	const [spin, setSpin] = useState(false)

	useEffect(() => {
		refreshAuthenticatedUser().then(freshUser => {
			if (freshUser instanceof CognitoUser) {
				navigate("/me")
			}
			else {
				setLoading(false)
			}
		})
	}, [refreshAuthenticatedUser])

	const onSubmit = async (data) => {

		let { name, password, email, optinUpdates, optinMarketing } = data;

		name = email

		setSpin(true)

		try {
			await signup(name, email, password, optinUpdates, optinMarketing)
			navigate("/verify", {
				state: {
					email: email,
					notConfirmed: true
				}
			})
		}
		catch (err) {
			setSignupErr(err)
		}
		finally {
			setSpin(false)
		}
	};

	const instructions = typeof uiStore.selectedPlan !== 'undefined' ? <div>
		To get started, please create a file.io account. If you already have an account, you can <Link to="/login">log in here</Link>.
	</div> : null

	const signupForm = <>
		<SEO title="Sign Up" />
		<AccountLayout onSubmit={handleSubmit(onSubmit)}
			message={!instructions ? 'Sign up for free to do cool stuff with your files.' : undefined}
			instructions={instructions}
		>
			<label htmlFor="email">What is your email address?</label>
			<input
				name="email"
				type="text"
				placeholder="Email"
				ref={register({
					required: true,
					validate: value => {
						return emailValidator.validate(value)
					}
				})} />
			{errors.email && <div className="error">Please enter a valid email address</div>}
			<label htmlFor="password">Create a password</label>
			<input
				name="password"
				type="password"
				placeholder="Password"
				ref={register({
					required: true,
					validate: value => schema.validate(value)
				})} />
			{watchPassword && !schema.validate(watchPassword) && <PasswordChecklist schema={schema} password={watchPassword} tw="mb-4" />}
			{signupErr && <div tw="text-red mb-4 w-full pl-1">{signupErr.message}{signupErr.code === 'UsernameExistsException' && <span> If you would like to use this account, you can <Link to="/login">log in here</Link>.</span>}</div>}


			<label tw="inline! self-start! mt-4" htmlFor="optinUpdates">
				<input
					name="optinUpdates"
					id="optinUpdates"
					type="checkbox"
					ref={register({})}
					tw="inline! w-auto! self-start! mr-2 mb-0!"
				/>
				Notify me about file.io news and updates
			</label>

			<label tw="inline! self-start! mb-4" htmlFor="optinMarketing">
				<input
					name="optinMarketing"
					id="optinMarketing"
					type="checkbox"
					ref={register({})}
					tw="inline! w-auto! self-start! mr-2 mb-0!"
				/>
				Notify me about special offers and deals
			</label>

			<Button
				special={true}
				type="submit"
				tw="w-full mt-2"
				spin={spin}
			>Create Account</Button>
			<div tw="text-center text-sm mt-4">
				By signing up, you agree to the <a href="/tos" target="top">Terms of Service</a> and <a href="/privacy" target="top">Privacy Policy</a>
			</div>
			<div tw="mt-8">
				Already have an account? <Link to="/login">Log in</Link>.
			</div>
		</AccountLayout>
	</>

	const loadingScreen = <div></div>

	return loading ? loadingScreen : signupForm

})


export default SignUp
