import React, { useState, useEffect, useRef, useCallback, useContext } from "react"

import { TooltipWrapper } from "../tooltip"
import Button from "../button"
import tw, { css } from "twin.macro"
import { CopyToClipboard } from 'react-copy-to-clipboard'

const DownloadLink = ({ link, tooltipTarget, group }) => {

	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [selected, setSelected] = useState(false)
	const inputRef = useRef()

	return <TooltipWrapper
		tip={<div>Download link copied to your clipboard!</div>}
		options={{
			id: "downloadLink",
			placement: "bottom",
			middlewares: [],
			interactions: ['click'],
			autoCloseDelay: 3000,
			group,
			breakpoints: {
				sm: {
					placement: 'right',
					containerElement: tooltipTarget
				}
			}
		}}
		isOpen={tooltipOpen}
		onClose={() => { setTooltipOpen(false) }}
	>
		<CopyToClipboard onCopy={() => { setTooltipOpen(true) }} text={link} >
			<div
				tw="border border-blue! rounded-md h-10 flex items-center justify-center text-sm mb-0!"
				ref={inputRef}
				type="text"
				readOnly
				// value={link.replace(process.env.GATSBY_API_URL,'https://file.io')}
				value={link}
				tabIndex={0}
				onFocus={event => { setSelected(true) }}
				onBlur={event => { setSelected(false) }}
			>
				<span
					tw="select-none"
					css={
						selected && {
							...tw`bg-blue text-white`
						}
					}
				>{link}</span></div>
		</CopyToClipboard>
	</TooltipWrapper>
}

export default DownloadLink